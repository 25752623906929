














































import Vue from "vue";
import { email, required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  FORGOT_PASSWORD,
  RESET_PASS_USER_EMAIL
} from "@/store/modules/auth/constants";
import BackToLoginBtn from "@/components/auth/BackToLoginBtn.vue";
import ResetPasswordHeader from "@/components/auth/ResetPasswordHeader.vue";
import router from "@/router";

export default Vue.extend({
  name: "EmailInput",
  components: { ResetPasswordHeader, BackToLoginBtn },
  data() {
    return {
      email: null
    };
  },
  computed: {
    email_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push(this.$t("rules.required"));
      !this.$v.email.email && errors.push(this.$t("rules.email"));
      return errors;
    },
    ...mapGetters("auth", {
      auth_loading: AUTH_LOADING
    })
  },
  methods: {
    ...mapActions("auth", {
      forgot_password: FORGOT_PASSWORD
    }),
    ...mapMutations("auth", {
      set_email_for_reset_pass: RESET_PASS_USER_EMAIL
    }),
    async submit_form() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const obj = {
          email: this.email
        };
        const response = await this.forgot_password(obj);
        if (response) {
          this.set_email_for_reset_pass(this.email);
          await router.push("/auth/reset-password/verify");
          this.$v.$reset();
        }
      }
    }
  },
  validations() {
    return {
      email: {
        required,
        email: email
      }
    };
  }
});
